import * as React from 'react';

// context
import { usePropertyContext } from '@core/context';

// mui
import { Box, TextField, Popover, Typography, Stack } from '@mui/material';
import { ButtonGroup, Button } from '@mui/material';


interface Props {
  size: 'small' | 'medium'
  disabled: boolean
  values_id_bedrooms: number[]
  values_id_bathrooms: number[]
  onChange: (bedrooms: number[], bathrooms: number[]) => void
}

export default function SpacesField({ onChange, values_id_bedrooms, values_id_bathrooms, ...props }: Props) {

  // context
  const propertyContext = usePropertyContext()
  const { contextLoaded } = propertyContext.state

  // states
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [bedrooms, setBedrooms] = React.useState<number[]>([])
  const [bathrooms, setBathrooms] = React.useState<number[]>([])
  const [allowCallback, setAllowCallback] = React.useState<boolean>(false)

  // computed
  const open = Boolean(anchorEl);

  // effects
  React.useEffect(() => {
    if (!allowCallback) return

    onChange(bedrooms, bathrooms)
  }, [bedrooms, bathrooms])

  React.useEffect(() => {

    if (!contextLoaded) return

    setBedrooms(c => ([
      ...c,
      ...values_id_bedrooms,
    ]))

    setBathrooms(c => ([
      ...c,
      ...values_id_bathrooms,
    ]))

    setTimeout(() => {
      setAllowCallback(true)
    }, 100)

  }, [contextLoaded])

  // handlers

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const makeTitle = () => {
    let title = ''
    if (bedrooms.length === 0) {
      title += 'Any Bed'
    }
    else {
      title += bedrooms.join('+') + '+ bd'
    }
    title += ', '
    if (bathrooms.length === 0) {
      title += 'Any Bath'
    }
    else {
      title += bathrooms.join('+') + '+ ba'
    }
    return title
  }

  const renderButtons = (title: string, items: number[], values: number[], setValues: React.Dispatch<React.SetStateAction<number[]>>) => {

    return (
      <Box>
        <Typography variant="subtitle1" gutterBottom>
          Number of {title}
        </Typography>

        <ButtonGroup fullWidth variant="outlined" sx={{
          gap: 2,
        }}>
          <Button
            variant={values.length === 0 ? 'contained' : 'outlined'}
            onClick={() => {
              setValues([])
            }}>
            Any
          </Button>
          <ButtonGroup fullWidth variant="outlined">
            {items.map((item, index) => (
              <Button key={index}
                variant={values.includes(item) ? 'contained' : 'outlined'}
                onClick={() => {
                  setValues((c) => {
                    // let res = [...c, item]
                    // if (c.includes(item)) {
                    //   res = c.filter(i => i !== item)
                    // }
                    // return [...res]
                    return [item]
                  })
                }}
                sx={{
                  px: 1,
                }}>
                {item}+
              </Button>
            ))}
          </ButtonGroup>
        </ButtonGroup>
      </Box>
    );
  }

  return (
    <Box>

      <TextField
        disabled={props.disabled}
        onClick={handleClick}
        fullWidth
        size={props.size}
        label="Spaces"
        variant="outlined"
        value={makeTitle()}
        InputProps={{
          readOnly: true,
        }}
        focused={open}
        sx={{
          // pointerEvents: 'none',
        }}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            // width: width,
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transitionDuration={0}
      >
        <Box sx={{
          p: 5,
        }}>

          <Stack spacing={5}>
            {renderButtons('Bedrooms', [1, 2, 3, 4, 5], bedrooms, setBedrooms)}
            {renderButtons('Bathrooms', [1, 1.5, 2, 3, 4], bathrooms, setBathrooms)}
          </Stack>

        </Box>
      </Popover>

    </Box>
  );
}
