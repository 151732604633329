import * as React from 'react';

// context
import { usePropertyContext } from '@core/context';

// mui
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function CurrencyMenu() {

  // context
  const propertyContext = usePropertyContext()

  // states
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // computed
  const { currency } = propertyContext.state
  const currencies = propertyContext.state.context.currencies

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button variant="outlined" size="small" onClick={handleClick}>
        {currency}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {currencies.map((option) => (
          <MenuItem
            key={option.name}
            selected={option.name === currency}
            onClick={() => {
              propertyContext.setCurrency(option.name)
              handleClose()
            }}
            sx={{
              typography: {
                xs: 'subtitle1',
              },
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
