import { useEffect, useState } from 'react'
import { useRouter } from 'next/router';

// @core
import { usePropertyContext } from '@core/context';

// @configs
import routes from '@configs/routes';

// @hooks
import useMedia from '@hooks/useMedia';

// mui
import { Box, Button } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';

// icons
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// blocks
import LocationField from './LocationField'
import TypesField from './TypesField'
import PriceField from './PriceField'
import SpacesField from './SpacesField'
import TagsField from './TagsField'


interface Props {
  expanded: boolean
}

export default function SearchPanel(props: Props) {

  // context
  const propertyContext = usePropertyContext()
  const { filterSettings, filterSettingsLoaded } = propertyContext.state

  // hooks
  const router = useRouter()
  const media = useMedia()

  // states
  const [collapsed, setCollapsed] = useState(props.expanded);
  const [mounted, setMounted] = useState(false);

  // computed
  const filterLoaded = propertyContext.filterLoaded
  const formDisabled = !propertyContext.filterLoaded

  // computed
  const inputSize = media.down.xl ? 'small' : 'medium'
  const buttonSize = (() => {
    if (media.only.lg) return 'medium'
    return 'large'
  })()

  // reset filters by reset button
  useEffect(() => {
    if (router.query.reset) {
      router.replace({
        pathname: router.pathname,
        query: {},
      }, undefined, { shallow: true })
    }
  }, [router.query.reset]);

  // reload filter settings
  useEffect(() => {

    setMounted(false)
    setTimeout(() => {
      setMounted(true)
    }, 100)

  }, [router.pathname, router.query])

  // handlers
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault()

    propertyContext.applyFilters()
  }

  // render
  if (router.query.reset || !mounted || !filterLoaded) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 60,
      }}>
        <CircularProgress size={20} color="inherit" />
      </Box>
    )
  }

  const renderForm = () => {
    return (
      <Box>

        {/* <pre>
          {JSON.stringify(filterSettings, null, 2)}
        </pre> */}

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexWrap: {
              xs: 'wrap',
              // md: 'wrap',
              lg: 'nowrap',
            },
            columnGap: 5,
            rowGap: 4,
          }}>
          <Box sx={{
            flexGrow: 1,
            width: '100%',
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              md: '1fr 1fr',
              lg: '1fr 1fr 1fr 1fr',
            },
            columnGap: 2,
            rowGap: 4,
          }}>
            <Box sx={{
              minWidth: 220,
            }}>
              <LocationField
                size={inputSize}
                disabled={formDisabled}
                values_id={filterSettings.locations}
                onChange={(locations) => {
                  // alert('locations')
                  propertyContext.updateFilterSettings({
                    locations,
                  })
                }}
              />
            </Box>
            <Box sx={{
              minWidth: 220,
            }}>
              <TypesField
                size={inputSize}
                disabled={formDisabled}
                values_id={filterSettings.types}
                onChange={(types) => {
                  // alert('types')
                  propertyContext.updateFilterSettings({
                    types,
                  })
                }}
              />
            </Box>
            <Box>
              <PriceField
                size={inputSize}
                disabled={formDisabled}
                values={filterSettings.price}
                onChange={(price) => {
                  // alert('price')
                  propertyContext.updateFilterSettings({
                    price,
                  })
                }}
              />
            </Box>
            <Box>
              <SpacesField
                size={inputSize}
                disabled={formDisabled}
                values_id_bedrooms={filterSettings.bedrooms}
                values_id_bathrooms={filterSettings.bathrooms}
                onChange={(bedrooms, bathrooms) => {
                  // alert('rooms')
                  propertyContext.updateFilterSettings({
                    bedrooms,
                    bathrooms,
                  })
                }}
              />
            </Box>

          </Box>

          <Box sx={{
            display: 'flex',
            flex: {
              xs: '1 1 auto',
              md: '0 1 auto',
            },
            gap: 2,
          }}>
            <TagsField>
              {(handleClick) => (
                <Button
                  size={buttonSize}
                  fullWidth
                  disabled={formDisabled}
                  onClick={handleClick}
                  variant="outlined"
                  color="primary"
                  sx={{
                    px: {
                      xs: 1,
                    },
                    width: {
                      xs: 'auto',
                    }
                  }}
                >
                  <FilterListIcon />
                </Button>
              )}
            </TagsField>
            <Button
              size={buttonSize}
              fullWidth
              disabled={formDisabled}
              type="submit"
              startIcon={<SearchIcon />}
              variant="contained"
              color="primary"
            >
              Search
            </Button>
          </Box>
        </Box>

      </Box>
    )
  }

  if (media.down.md && !props.expanded) {
    return (
      <Box>
        <Button
          startIcon={(
            <ExpandMoreIcon sx={{
              transition: 'transform 0.3s',
              transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
            }} />
          )}
          size='large'
          fullWidth variant="outlined" color="secondary"
          onClick={() => {
            setCollapsed(c => !c)
          }}>
          Properties Finder
        </Button>
        <Collapse in={collapsed}>
          <Box sx={{
            pt: 5,
          }}>
            {renderForm()}
          </Box>
        </Collapse>
      </Box>
    )
  }

  return (
    <Box>
      {renderForm()}
    </Box>
  )
}
