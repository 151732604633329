import { useEffect, useState } from 'react';

// context
import { usePropertyContext } from '@core/context';

// mui
import { Box, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


interface Props {
  size: 'small' | 'medium'
  disabled: boolean
  values_id: string[]
  onChange: (types: string[]) => void
}

interface TypeData {
  id: string;
  label: string;
}

export default function TypesField({ onChange, ...props }: Props) {

  // context
  const propertyContext = usePropertyContext()

  // states
  const [selectedOptions, setSelectedOptions] = useState<TypeData[]>([]);

  // computed
  const { context, contextLoaded } = propertyContext.state

  useEffect(() => {
    props.values_id.forEach((id) => {
      const option = context.types.find((item) => item.id === id)
      if (option) {
        setSelectedOptions((prev) => [...prev, { id: option.id, label: option.name }])
      }
    })
  }, [contextLoaded])


  // callbacks
  const handleCallback = (options: TypeData[]) => {
    onChange(options.map((item) => item.id))
  }

  // handlers

  const handleCheckboxChange = (option: TypeData) => {

    setSelectedOptions((prev) => {
      const isExist = prev.find((item) => item.id === option.id);
      let res = [...prev, option]
      if (isExist) {
        res = prev.filter((item) => item.id !== option.id);
      }

      handleCallback(res)
      return res
    });
  }

  const options: TypeData[] = context.types.map((type) => ({
    id: type.id,
    label: type.name,
  }));

  return (
    <Autocomplete
      disabled={props.disabled}
      multiple
      size={props.size}
      limitTags={2}
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      renderTags={(value, getTagProps) => {
        return (
          <Typography
            variant="subtitle1"
            sx={{
              ml: 2,
              pointerEvents: 'none',
            }}
          >
            {value.length} selected
          </Typography>
        )
      }}
      renderOption={(props, option, { selected }) => (
        <Box
          {...props}
          component="li"
          onClick={() => handleCheckboxChange(option)}>
          <Checkbox
            size="small"
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selectedOptions.some((item) => item.id === option.id)}
          />
          <Typography variant="subtitle2" color="text.primary">
            {option.label}
          </Typography>
        </Box>
      )}
      value={selectedOptions}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'clear') {
          setSelectedOptions([])
          handleCallback([])
          return
        }
      }}
      renderInput={(params) => (
        <TextField {...params}
          sx={{
            '& .MuiTypography-root': {
              typography: {
                xs: 'subtitle1',
                xl: 'body1'
              },
            }
          }}
          label="Property Type" />
      )}
      sx={{
        // minWidth: 220,
      }}
    />
  );
}

