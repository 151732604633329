import * as React from 'react';

// context
import { usePropertyContext } from '@core/context';

// mui
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { FormControlLabel, FormGroup } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Checkbox from '@mui/material/Checkbox';

// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// libs
import _ from 'lodash';

// blocks
import PopoverBox from './PopoverBox'


interface LocationData {
  id: number;
  name: string;
  variant: 'country' | 'sublocation';
  count_properties: number;
  parentId?: number;
}


interface Props {
  size: 'small' | 'medium'
  disabled: boolean
  values_id: number[]
  onChange: (locations_id: number[]) => void
}

export default function LocationField({ onChange, ...props }: Props) {

  // context
  const propertyContext = usePropertyContext()
  const { context, contextLoaded } = propertyContext.state

  // states
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [selectedOptions, setSelectedOptions] = React.useState<LocationData[]>([]);

  // computed
  // const locations = context.locations.filter((item) => item.count_properties > 0)
  const locations = context.locations

  // unique selected options
  // const ids = selectedOptions.map((item) => item.id)
  // const uniqueIds = _.uniq(ids)
  // const uniqueSelectedOptions = selectedOptions.filter((item) => uniqueIds.includes(item.id))

  React.useEffect(() => {
    const selectedOptions: LocationData[] = []

    // if (!contextLoaded) return
    // if (!props.values_id) return

    const { values_id } = props

    let newItems: LocationData[] = []

    // Load selected options
    values_id.forEach((id) => {

      context.locations.forEach((item) => {
        if (item.id === id) {
          // newItems.push(item)
        } else {
          item.sublocations.forEach((sublocation) => {
            if (sublocation.id === id) {
              newItems.push(sublocation)
            }
          })
        }
      })

      // Set all sublocations if parent is selected
      context.locations.forEach((item) => {
        if (item.variant === 'country' && item.id === id) {
          newItems.push(...item.sublocations)
        }
      })
    })



    // add newItems to setSelectedOptions if id not exists
    let uniqueItemsById: LocationData[] = _.uniqBy(newItems, 'id')

    if (uniqueItemsById) {
      setSelectedOptions(c => {
        // return only unique items by id
        const uniqueItems = _.uniqBy([...c, ...uniqueItemsById], 'id')
        return uniqueItems
      })

    }

    setLoaded(true)

  }, [contextLoaded])

  // return selected options
  React.useEffect(() => {
    if (!loaded) return
    const ids = selectedOptions.map((item) => item.id)
    const uniqueIds = _.uniq(ids)
    onChange(uniqueIds)
  }, [selectedOptions])

  const changeSelectedOptions = (location: LocationData) => {
    const index = selectedOptions.findIndex((item) => item.id === location.id)
    if (index === -1) {
      setSelectedOptions([...selectedOptions, location])
    } else {
      setSelectedOptions(selectedOptions.filter((_, i) => i !== index))
    }
  }


  const labelValue = () => {
    // unique selected options
    const uniqueSelectedOptions = _.uniqBy(selectedOptions, 'id')

    if (uniqueSelectedOptions.length === 1) return uniqueSelectedOptions[0].name
    if (uniqueSelectedOptions.length > 1) return `${uniqueSelectedOptions.length} locations`
    return 'All Locations'
  }

  return (
    <Box>

      {/* <pre>
        {JSON.stringify(selectedOptions, null, 2)}
      </pre> */}

      <PopoverBox
        size={props.size}
        onClear={() => {
          // Clear all selected options
          setSelectedOptions([])
        }}
        showClear={selectedOptions.length > 0}
        value={labelValue()}>

        {locations.map((item) => {

          const panelId = `panel-${item.id}`
          const isExpanded = expanded === panelId



          const selectedSublocations = item.sublocations.filter((sublocation) => {
            return selectedOptions.findIndex((item) => item.id === sublocation.id) !== -1
          })

          const renderAllSublocations = () => {

            // Return if no sublocations
            if (item.sublocations.length === 0) {

              const parentIsSelected = selectedOptions.findIndex((o) => o.id === item.id) !== -1

              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={parentIsSelected}
                      onChange={() => {

                        // Add or remove parent location
                        if (parentIsSelected) {
                          setSelectedOptions(c => {
                            const newResponse = c.filter((item) => item.id !== item.id)
                            return newResponse
                          })
                        } else {
                          const country = {
                            id: item.id,
                            name: item.name,
                            variant: item.variant,
                            count_properties: item.count_properties,
                          }

                          setSelectedOptions([...selectedOptions, country])
                        }

                      }} />
                  }
                  label="This area"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontWeight: 600,
                    }
                  }}
                />
              )
            }

            const allAreSelected = item.sublocations.every((sublocation) => {
              if (!sublocation) return false
              return selectedOptions.findIndex((item) => item.id === sublocation.id) !== -1
            })

            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allAreSelected}
                    onChange={() => {
                      // if allAreSelected remove all sublocations

                      if (allAreSelected) {
                        setSelectedOptions(c => {
                          const newResponse = c.filter((item) => item.id !== item.id)
                          return newResponse
                        })
                      }
                      else {
                        const country = {
                          id: item.id,
                          name: item.name,
                          variant: item.variant,
                          count_properties: item.count_properties,
                        }

                        setSelectedOptions([...selectedOptions, country, ...item.sublocations])
                      }

                    }} />
                }
                label="All areas"
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontWeight: 600,
                  }
                }}
              />
            )
          }

          return (
            <Accordion
              key={item.id}
              disableGutters
              expanded={isExpanded}
              onChange={() => {
                setExpanded(isExpanded ? false : panelId)
              }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>
                  {item.name}
                  {/* Show selected sublocations */}
                  {selectedSublocations.length > 0 ? (
                    <Typography
                      component="span"
                      variant="caption"
                      color="text.secondary"
                      sx={{
                        ml: 1,
                        fontWeight: 400,
                      }}>
                      ({selectedSublocations.length})
                    </Typography>
                  ) : ''}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>


                <FormGroup>

                  {renderAllSublocations()}

                  {item.sublocations.map((sublocation) => {
                    const isChecked = selectedOptions.findIndex((item) => item.id === sublocation.id) !== -1
                    return (
                      <FormControlLabel
                        key={sublocation.id}
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={() => {
                              changeSelectedOptions(sublocation)
                            }}
                            name={sublocation.name} />
                        }
                        label={sublocation.name}
                      />
                    )
                  })}
                </FormGroup>


              </AccordionDetails>
            </Accordion>
          )
        })}

      </PopoverBox>


    </Box>
  );
}



