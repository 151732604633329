import * as React from 'react';

// mui
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { IconButton, InputAdornment } from '@mui/material';
import { Box, TextField } from '@mui/material';

// icons
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface Props {
  size: 'small' | 'medium'
  value: string
  children: React.ReactNode
  onClear: () => void
  showClear: boolean
}

export default function MenuListComposition({ children, ...props }: Props) {
  const [open, setOpen] = React.useState(false);
  const [mouseOver, setMouseOver] = React.useState(false);

  const anchorRef = React.useRef<HTMLInputElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box sx={{
    }}>

      <TextField
        ref={anchorRef}
        size={props.size}
        fullWidth
        label="Locations"
        value={props.value}
        variant="outlined"
        onClick={handleToggle}
        onMouseOver={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end" sx={{
              mr: -2,
              '& button': {
                p: 1,
              }
            }}>
              {(props.showClear && mouseOver) && (
                <IconButton
                  sx={{
                    '& svg': {
                      fontSize: '1.2rem',
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation()
                    props.onClear()
                  }}>
                  <CloseIcon />
                </IconButton>
              )}
              <IconButton size="small">
                <ArrowDropDownIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        // disablePortal
        sx={{
          zIndex: theme => theme.zIndex.appBar - 1,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper sx={{
              // zIndex over appbar
              zIndex: theme => theme.zIndex.appBar + 1,
            }}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{
                  minWidth: 330,
                  maxHeight: {
                    xs: 300,
                    md: 400,
                  },
                  // bgcolor: 'red',
                  overflowY: 'auto',
                }}>
                  {children}
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}
