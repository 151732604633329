import * as React from 'react';

// context
import { usePropertyContext } from '@core/context';

// mui
import { Box, TextField, Popover, Typography, Slider, Stack } from '@mui/material';
import { Tooltip } from '@mui/material';
import { SliderValueLabelProps } from '@mui/material/Slider';

// @components
import NumberField from '@components/MuiFields/NumberField'

// blocks
import CurrencyMenu from './CurrencyMenu';


function ValueLabelComponent(props: SliderValueLabelProps) {

  // context
  const propertyContext = usePropertyContext()

  // props
  const { children, value } = props;

  // functions
  const renderValue = (value: number) => propertyContext.formatCurrencyPretty(value)

  // computed
  const title = renderValue(value)

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={title}>
      {children}
    </Tooltip>
  );
}


interface Props {
  size: 'small' | 'medium'
  disabled: boolean
  values: number[]
  onChange: (price: number[]) => void
}

export default function PriceField({ onChange, ...props }: Props) {

  // context
  const propertyContext = usePropertyContext()
  const { contextLoaded } = propertyContext.state

  // states
  const [value, setValue] = React.useState<number[]>([0, 0]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // computed
  const open = Boolean(anchorEl);

  const { currencySymbol } = propertyContext
  const prices = propertyContext.state.context.prices

  const config = {
    min: prices.min || 0,
    max: prices.max || 0,
    step: 100000,
  };

  // effects
  React.useEffect(() => {
    setValue([config.min, config.step * 100])
  }, [config.min, config.max])

  React.useEffect(() => {
    if (!contextLoaded) return

    onChange(value)
  }, [value])

  React.useEffect(() => {
    if (!contextLoaded) return

    if (props.values.length === 2) {
      setValue(props.values)
    }

  }, [contextLoaded])

  // functions
  const renderValue = (value: number) => propertyContext.formatCurrencyPretty(value)

  // handlers
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    setValue(newValue);
  };

  const handleInputChange = (direction: 'from' | 'to') => (newValue: string) => {
    const numberValue = newValue as any as number
    let updateValue = [0, 0]

    if (direction === 'from') {
      updateValue = [numberValue, value[1]]
    }
    if (direction === 'to') {
      updateValue = [value[0], numberValue]
    }

    setValue(updateValue);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const makeTitle = () => {
    const v1 = renderValue(value[0])
    const v2 = renderValue(value[1])

    if (value[1]) {
      return `${v1} - ${v2}`
    }

    return `from ${v1}`
  }

  return (
    <Box>

      <TextField
        fullWidth
        disabled={props.disabled}
        onClick={handleClick}
        size={props.size}
        label="Price"
        variant="outlined"
        value={makeTitle()}
        InputProps={{
          readOnly: true,
        }}
        focused={open}
        sx={{
          // pointerEvents: 'none',
        }}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: 300
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transitionDuration={0}
      >
        <Box sx={{
          p: 5,
        }}>
          <Stack spacing={5}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
              <Typography variant="body2" color="text.secondary">
                Price {currencySymbol}
              </Typography>

              <CurrencyMenu />
            </Box>
            <Box sx={{
              display: 'flex',
              gap: 2,
            }}>
              <NumberField
                label="From"
                value={value[0]}
                onChange={handleInputChange('from')}
                variant='outlined'
                size="small"
                config={{
                  min: prices.min || 0,
                  max: prices.max || 0,
                }}
              />
              <NumberField
                label="To"
                value={value[1]}
                onChange={handleInputChange('to')}
                variant='outlined'
                size="small"
                config={{
                  min: prices.min || 0,
                  max: prices.max || 0,
                }}
              />
            </Box>

            <Box sx={{
              pt: 2,
            }}>

              <Slider
                valueLabelDisplay="auto"
                slots={{
                  valueLabel: ValueLabelComponent,
                }}
                step={config.step}
                min={config.min}
                max={config.max}
                value={value}
                onChange={handleSliderChange}
              />

            </Box>
          </Stack>
        </Box>
      </Popover>

    </Box>
  );
}
