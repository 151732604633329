import * as React from "react";
import { TextField, OutlinedTextFieldProps } from "@mui/material";

interface NumberInputCustomProps {
  onChange: (value: number) => void;
  config: FieldConfig;
  value: number;
}

const thousandSeparator = (value: string | number) => {
  if (!value) return 0
  if (typeof value === "number") {
    value = String(value);
  }
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const NumberInputCustom: React.ForwardRefExoticComponent<
  NumberInputCustomProps & React.RefAttributes<HTMLInputElement>
> = React.forwardRef((props, ref) => {
  const { onChange, value, config, ...other } = props;

  const newValue = thousandSeparator(value)

  return (
    <input
      {...other}
      value={newValue}
      type="text"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        value = value.replace(/\D/g, ""); // Remove non-digit characters

        const numericValue = parseInt(value.replace(/\s/g, ""), 10)

        if (config.max !== undefined) {
          if (!isNaN(numericValue) && numericValue > config.max) {
            value = String(config.max);
          }
        }

        if (config.min !== undefined) {
          if (!isNaN(numericValue) && numericValue < config.min) {
            value = String(config.min);
          }
        }

        onChange(parseInt(value.toString()));
      }}
    />
  );
});

interface FieldConfig {
  min: number;
  max: number;
}

interface NumberFieldProps extends Omit<OutlinedTextFieldProps, 'onChange'> {
  onChange: (value: string) => void;
  config: FieldConfig;
}

export default function NumberField({ config, ...props }: NumberFieldProps) {
  const handleChange = (value: any) => {
    props.onChange(value); // Преобразуем значение к строке
  };

  return (
    <TextField
      {...props}
      onChange={handleChange}
      inputProps={{
        config,
        min: config.min,
        max: config.max,
        inputMode: 'numeric',
      }}
      InputProps={{
        inputComponent: NumberInputCustom as any,
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}
