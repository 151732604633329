
import { useState, useEffect, use } from 'react';

// @core
import { usePropertyContext } from '@core/context';

// mui
import { Box, Popover, Portal, Typography, Badge, Divider, Button } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


interface Props {
  children: (handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void) => React.ReactNode
}

export default function TagsField({ children }: Props) {

  // context
  const propertyContext = usePropertyContext()

  // states
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [checked, setChecked] = useState<number[]>([]);

  // computed
  const open = Boolean(anchorEl);
  const { contextLoaded } = propertyContext.state
  const tags = contextLoaded ? propertyContext.state.context.tags : []

  // effects
  useEffect(() => {
    if (contextLoaded) {
      setChecked(propertyContext.state.filterSettings.tags)
    }
  }, [contextLoaded])

  useEffect(() => {

    propertyContext.updateFilterSettings({
      tags: checked,
    })

  }, [checked])


  // handlers

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(c => {
      const index = c.indexOf(Number(event.target.value))
      if (index === -1) {
        return [...c, Number(event.target.value)]
      } else {
        return c.filter((_, i) => i !== index)
      }
    })
  }

  const renderContent = () => {
    return (
      <FormGroup>
        {tags.map((item) => {
          return (
            <FormControlLabel
              key={item.id}
              sx={{
                'userSelect': 'none',
              }}
              control={(
                <Checkbox
                  value={item.id}
                  checked={checked.indexOf(item.id) !== -1}
                  onChange={(event, checked) => {
                    handleChecked(event)
                  }}
                />
              )}
              label={item.name} />
          )
        })}
      </FormGroup>
    )
  }

  return (
    <>

      {/* <pre>
        {JSON.stringify(propertyContext.state.filterSettings, null, 2)}
      </pre> */}

      <Portal>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box sx={{
            p: 5,
            minWidth: 300,
          }}>
            <Typography variant="h6" gutterBottom>
              Additonal filters
            </Typography>
            {renderContent()}
            <Box sx={{
              pt: 5,
            }}>
              <Divider />
              <Button
                onClick={() => {
                  propertyContext.resetFilters()
                }}
                sx={{
                  boxShadow: 'none',
                }}
                disableElevation
                variant="contained" color="secondary">
                Reset filters
              </Button>
            </Box>
          </Box>
        </Popover>
      </Portal>

      <Badge
        badgeContent={checked.length}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        color="primary">
        {children(handleClick)}
      </Badge>
    </>
  )
}